<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Novo agendamento">
          <form-adicionar-agendamento
            @exibirComprovanteAgendamento="exibirComprovanteAgendamento"
            @exibirComprovante="exibirComprovanteTriagem"
          />
          <v-dialog v-model="showComprovanteAgendamento" fullscreen>
            <v-card>
              <comprovante-agendamento ref="comprovante" />
            </v-card>
          </v-dialog>
          <v-dialog v-model="showComprovante" fullscreen>
            <v-card>
              <comprovante ref="comprovanteTriagem" />
            </v-card>
          </v-dialog>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TipoAgendamento from '@/utils/agendamento'
import FormAdicionarAgendamento from '@/components/patio/NovoAgendamento/FormAdicionarAgendamento.vue'
import ComprovanteAgendamento from '@/components/patio/NovoAgendamento/comprovanteAgendamento/Componente.vue'
import Comprovante from '@/components/patio/NovoAgendamento/comprovante/Componente.vue'

export default {
  name: 'Agendamento',
  provide() {
    return {
      fecharComprovanteAgendamento: this.fecharComprovanteAgendamento,
      fecharComprovanteTriagem: this.fecharComprovanteTriagem,
      showShortcurt: this.showShortcurt,
    }
  },
  props: {
    tipoAgendamento: {
      type: Number,
      default: TipoAgendamento.Novo,
    },
    pedidoSelecionado: {
      type: Object,
    },
    programacaoUnidade: {
      type: Object,
    },
    programacaoFornecedor: {
      type: Object,
    },
    programacaoRegiao: {
      type: Object,
    },
    programacaoCliente: {
      type: Object,
    },
  },
  data() {
    return {
      showShortcurt: true,
      showForm: false,
      showComprovanteAgendamento: false,
      showComprovante: false,
      page: {
        title: 'Novo agendamento',
      },
      breadcrumbs: [
        {
          text: 'Logística',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Agendamento',
          disabled: true,
        },
      ],
    }
  },
  components: {
    FormAdicionarAgendamento,
    ComprovanteAgendamento,
    Comprovante,
  },
  computed: {
    ...mapState('agendamentos', ['agendamento']),
  },
  created() {
    this.reset()
    this.updateAtributo({
      valor: this.tipoAgendamento,
      nomeAtributo: 'tipoAgendamento',
    })
    if (this.tipoAgendamento === TipoAgendamento.DoPedido) {
      this.updateAtributo({
        valor: this.pedidoSelecionado,
        nomeAtributo: 'pedidoSelecionado',
      })
    }
    if (this.tipoAgendamento === TipoAgendamento.DaProgramacao) {
      this.updateAtributo({
        valor: this.programacaoUnidade,
        nomeAtributo: 'programacaoUnidade',
      })
      this.updateAtributo({
        valor: this.programacaoFornecedor,
        nomeAtributo: 'programacaoFornecedor',
      })
      this.updateAtributo({
        valor: this.programacaoRegiao,
        nomeAtributo: 'programacaoRegiao',
      })
      this.updateAtributo({
        valor: this.programacaoCliente,
        nomeAtributo: 'programacaoCliente',
      })
    }
  },
  methods: {
    ...mapActions('agendamentos', ['reset', 'updateAtributo']),
    async exibirComprovanteTriagem() {
      this.showComprovante = true
      this.$nextTick(() => {
        this.$refs.comprovanteTriagem.imprimirComprovante()
      })
    },

    fecharComprovanteTriagem() {
      this.showComprovante = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },

    async exibirComprovanteAgendamento() {
      this.showComprovanteAgendamento = true
      this.$nextTick(() => {
        this.$refs.comprovante.imprimirComprovante()
      })
    },

    fecharComprovanteAgendamento() {
      this.showComprovanteAgendamento = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },
  },
}
</script>

<style></style>
